<template>
<!--        JUST COPY THE CONTENTS OF THE FILE!!!       -->
<div>
    <v-card style="height: 100%; position: relative;">
        <v-toolbar flat>
            <v-toolbar-title style="font-size: 1rem !important">
                <b>
                    <v-text-field prefix="Pallet Count In Week" :suffix="year" v-model="isoWeek" type="number" style="text-align: center;" />

                </b>
            </v-toolbar-title>

        </v-toolbar>
        <b style="position: absolute; bottom: 0; right: 0; font-size: 3rem !important; margin: 16px;">{{ abbreviateNumber(count) }}</b>
    </v-card>
</div>
</template>

<script>
export default {
    props: {
        widget: {
            type: String,
            required: true,
        },
        columns: {
            type: Number,
            required: true,
        },
        rows: {
            type: Number,
            required: true,
        },
        widgetIndex: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        // w and h are the default number of columns and rows respectively that the widget takes up. 
        // Changing these values will not affect the default size as the size is stored in the widget itself. 
        // To change default size, either edit the widget in the Widget Dev Tools or change it in the widget.json file found in /server/src/static/widgets
        w: 1,
        h: 1,

        // cellWidth and cellHeight are the actual widths and heights respectively of a cell in the grid, based on the users viewport size
        // These values are calculated and changed if the grid resizes, for instance if the right bar opens or if the navbar opens.
        cellWidth: 0,
        cellHeight: 0,
        count: 1,
        isoWeek: 1,
        year: `in Season`
    }),
    mounted() {
        // DO NOT CHANGE THE CODE BELOW!!!
        // This code is used to calculate the number of columns and rows the widget takes up when it is resized
        // This is set up here because the sizes do not update dynamically from the parent component when the widget is resized
        // We need these values to resize / reorder the contents of the widget when it is resized
        // This also takes into account the resizing of the grid itself
        this.$nextTick(() => {
            const path = this.widget
            const grid = document.getElementById('grid');
            const widget = document.getElementById(`${path + '-' + this.widgetIndex}`);
            let gridWidth = grid.offsetWidth;
            let gridHeight = grid.offsetHeight;
            let cellWidth = gridWidth / 25;
            this.cellWidth = cellWidth;
            let cellHeight = gridHeight / 14;
            this.cellHeight = cellHeight;
            const gridResizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    gridWidth = entry.contentRect.width;
                    gridHeight = entry.contentRect.height;
                    cellWidth = gridWidth / 25;
                    this.cellWidth = cellWidth;
                    cellHeight = gridHeight / 14;
                    this.cellHeight = cellHeight;
                }
            });
            gridResizeObserver.observe(grid, {
                box: 'content-box'
            });
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    const widgetWidth = entry.contentRect.width;
                    const widgetHeight = entry.contentRect.height;
                    const widgetColumns = Math.round(widgetWidth / cellWidth);
                    this.w = widgetColumns;
                    const widgetRows = Math.round(widgetHeight / cellHeight);
                    this.h = widgetRows;
                    console.log(`Widget: ${widgetColumns} cols x ${widgetRows} rows`);
                }
            });
            resizeObserver.observe(widget, {
                box: 'content-box'
            });
        });
        // DO NOT CHANGE THE CODE ABOVE!!!
    },
    watch: {
        columns() {
            this.w = this.columns;
        },
        rows() {
            this.h = this.rows;
        },
        isoWeek() {
            this.getCount()
        }
    },
    computed: {

    },
    methods: {
        abbreviateNumber(number) {
            // Convert number to string
            let numStr = number.toString();

            // Split the string into parts before and after the decimal point (if any)
            let parts = numStr.split('.');

            // Format the part before the decimal point with spaces
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            // Join the parts back together with a decimal point (if any)
            return parts.join('.');
        },
        async formatDate(date, isStart) {
            if (isStart) {
                date.setHours(0, 0, 0, 0); // Set time to beginning of the day
            } else {
                date.setHours(23, 59, 59, 999); // Set time to end of the day
            }

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month since it's zero-based
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
        },
        async getCount() {
            let intYear = this.year.replace("in Season ", "");

            console.log({
                intYear,
                year: this.year
            })
            let data = await this.$API.getTUMPalletWeekCount({
                params: {
                    isoWeek: this.isoWeek,
                    year: parseInt(intYear)
                }
            })

            console.log(data)

            this.count = data.total
        },
        async getYear() {
            let date = new Date();

            let year = date.getFullYear();

            this.year = `in Season ${year}`
        },
        ISOWeek(dt) {
            var tdt = new Date(dt.valueOf());
            var dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            var firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4) {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
            }
            this.isoWeek = 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },
    },
    async created() {
        await this.getYear()
        this.ISOWeek(new Date())
    }
};
</script>

<style scoped></style>
